import React from "react"
import {EuiSpacer} from '@elastic/eui'

import ProductTable from '../../../components/Products/Compare/indoor-compare-table'

import BreadCrumbs from '../../../components/Layout/BreadCrumbs'
import NavButtons from '../../../components/Products/NavButtons'

import SEOHelmet from '../../../components/Layout/SEOHelmet'


const seodata = {
  title: 'Compare All INSTAR Indoor Camera Models',
  description: 'Compare All INSTAR Indoor Camera Models.',
  image: '/images/Search/QI_SearchThumb_SetupWizard.png',
  twitter: '/images/Search/QI_SearchThumb_SetupWizard.webp',
  locationEN: 'https://wiki.instar.com/en/',
  locationDE: 'https://wiki.instar.com/de/',
  locationFR: 'https://wiki.instar.com/fr/'
}


const IndoorCams = (props) => (
    <React.Fragment>

      <SEOHelmet
        title={seodata.title}
        description={seodata.description}
        image={seodata.image}
        location={props.location} />

      <BreadCrumbs
          locationBC={props.location}
          locationEN={props.location.pathname}
          locationDE='/de/Produkte/Compare/Indoor_Cameras/'
          locationFR='/fr/Products/Compare/Indoor_Cameras/'
          crumbLabel="Innenkameras" />
          
      <NavButtons />

      <h1>INSTAR IP Camera Comparison</h1>
      <EuiSpacer />
      <ProductTable />
    </React.Fragment>
  )

export default IndoorCams